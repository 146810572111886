import { useIntl } from '@finn/ui-utils';

import { GenericVehicleDetails } from '../types';

export const useProductLabel = (
  { availability, product_label }: GenericVehicleDetails,
  partnerDiscount?: number
) => {
  const { formatMessage } = useIntl();

  const label =
    (!availability && formatMessage('plp.outOfStock')) ||
    (!partnerDiscount && product_label?.label);

  return label;
};
