import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export const LoadingPlaceholder = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 0 402 268"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="42" y="56" rx="0" ry="0" width="0" height="1" />
    <rect x="-53" y="-23" rx="0" ry="0" width="684" height="439" />
    <rect x="50" y="45" rx="0" ry="0" width="1" height="0" />
  </ContentLoader>
);
