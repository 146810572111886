require('intersection-observer');

import { cn, getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import lazySizes from 'lazysizes';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';

import { Image } from './Image';
import { LoadingPlaceholder } from './LoadingPlaceholder';

const IMAGE_WIDTH = 296;
const IMAGE_HEIGHT = 176;

type Props = {
  vehiclePictureURL?: string;
  shouldPreload?: boolean;
  shouldShowPlaceholder?: boolean;
  className?: string;
  imageClassName?: string;
  altText?: string;
};

export const ProductImage = ({
  vehiclePictureURL,
  shouldPreload,
  shouldShowPlaceholder = true,
  className,
  imageClassName,
  altText = '',
}: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [shouldShowContentPlaceholder, setShouldShowContentPlaceholder] =
    useState<boolean>(shouldShowPlaceholder);
  const doesProductImageExist = Boolean(vehiclePictureURL);
  const vehicleImageUrl = doesProductImageExist
    ? getCloudinaryImgUrl(vehiclePictureURL, {
        w: IMAGE_WIDTH,
        h: IMAGE_HEIGHT,
        c: 'fill',
        dpr: 2,
        fl: 'progressive',
      })
    : '';
  const srcSet =
    vehicleImageUrl && getCloudinarySrcSet(vehicleImageUrl, [IMAGE_WIDTH]);

  const imageWrapperClass = `h-0 w-full relative top-4 pt-[60%] sm:min-h-[140px]`;
  const hiddenClass = 'opacity-0 w-0 h-0';
  const imageClass = `absolute inset-0 max-w-[90%] m-auto mix-blend-multiply transition-transform duration-200 hover:scale-105`;

  const imageClassOrHidden = shouldShowContentPlaceholder
    ? hiddenClass
    : imageClass;

  const handleImageLoading = () => {
    setShouldShowContentPlaceholder(false);
  };

  useEffect(() => {
    if (!vehiclePictureURL || shouldPreload) return;
    // If image is loaded from cache, onLoad does not trigger.
    if (imgRef.current?.complete && shouldShowPlaceholder) {
      lazySizes.loader.unveil(imgRef.current);
      handleImageLoading();
    }
  }, [vehiclePictureURL, shouldPreload, shouldShowPlaceholder]);

  if (shouldPreload) {
    return (
      <>
        <Head>
          <link rel="preload" as="image" href={vehicleImageUrl} />
        </Head>
        <div className={cn(imageWrapperClass, className)}>
          <Image
            src={vehicleImageUrl}
            srcSet={srcSet}
            sizes={`${IMAGE_WIDTH}px`}
            alt={altText}
            className={cn(imageClass, imageClassName)}
          />
        </div>
      </>
    );
  }

  return (
    <div className={cn(imageWrapperClass, className)}>
      {shouldShowContentPlaceholder && (
        <LoadingPlaceholder className={imageClass} />
      )}
      <Image
        ref={imgRef}
        variant="lazy-loading"
        src={vehicleImageUrl}
        srcSet={srcSet}
        className={cn(imageClassOrHidden, imageClassName)}
        alt={altText}
        onLoad={handleImageLoading}
      />
    </div>
  );
};
