import { useCurrentLocale, useIntl } from '@finn/ui-utils';

import { getPowerLabel } from '../helpers/card-utils';
import { isElectricVehicle } from '../helpers/vehicle';
import { GenericVehicleDetails } from '../types';

export const AttributesRowExp = ({
  vehicle,
}: {
  vehicle: GenericVehicleDetails;
}) => {
  const intl = useIntl();
  const { region } = useCurrentLocale();
  const isElectric = isElectricVehicle(vehicle.fuel);
  const power = getPowerLabel(intl, vehicle.power ?? 0, region, isElectric);

  return (
    <div>
      <div className="relative flex flex-wrap items-center text-ellipsis whitespace-nowrap">
        <div className="my-2 grid w-full grid-cols-2 gap-y-3 text-wrap">
          <span className="body-12-light">
            {intl.formatMessage('pdp.keyData.engine')}
          </span>
          <span className="body-12-semibold">
            {`${vehicle.engine} ${vehicle.trim_name ?? ''}`}
          </span>

          <span className="body-12-light">
            {intl.formatMessage('pdp.keyData.fuel')}
          </span>
          <span className="body-12-semibold">{vehicle.fuel}</span>

          {isElectric && vehicle.ev_range ? (
            <>
              <span className="body-12-light">
                {intl.formatMessage('plp.evRange')}
              </span>
              <span className="body-12-semibold">{`${vehicle.ev_range} km`}</span>
            </>
          ) : (
            <>
              <span className="body-12-light">
                {intl.formatMessage('pdp.keyData.power')}
              </span>
              <span className="body-12-semibold">{power}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
