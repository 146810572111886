import { showToast } from '@finn/design-system/atoms/toast';
import { CountryCode, useCurrentLocale, useIntl } from '@finn/ui-utils';
import useSWRInfinite from 'swr/infinite';

import { type GenericVehicleDetails } from '../../product-cards';
import { useDiscoveryConfig } from '../data/MetadataProvider';
import {
  GetVehiclesParams,
  GetVehiclesResponse,
} from '../data/product-fetchers';
import {
  FiltersResponse,
  FilterValuesObject,
  VehicleViewKey,
} from '../helpers/filters';

export const DEFAULT_LIMIT = 9;
export const DEDUPING_INTERVAL_MS = 1000 * 60 * 2; // 2 minutes

const getInfiniteLoadKey =
  (params: GetVehiclesParams) =>
  (pageIndex: number, prevData: GetVehiclesResponse | null) => {
    if (!prevData) {
      return params;
    }

    if (!params.offset && !prevData.offset) {
      return null;
    }

    return {
      ...params,
      offset: params.offset ?? prevData.offset,
    };
  };

export type UseProductListItemsParams = {
  filters?: FilterValuesObject;
  offset?: number;
  limit?: number;
  zipCode?: string;
};

export type UseProductListItemsConfig = {
  infinite?: boolean;
  fallbackData?: GetVehiclesResponse;
};

type UseProductListResult = {
  products: GenericVehicleDetails[];
  offset: number;
  limit: number;
  total: number;
  isLoading: boolean;
  isValidating: boolean;
  loadNextPage(): void;
};

export type FiltersType = {
  [key: string]: FiltersResponse;
};

export const useProductListItems = (
  params: UseProductListItemsParams = {},
  config?: UseProductListItemsConfig
): UseProductListResult => {
  const { locale, region } = useCurrentLocale();
  const i18n = useIntl();

  if (!params.filters) {
    params.filters = {};
  }
  if (!params.filters.view) {
    params.filters.view =
      region === CountryCode.US
        ? VehicleViewKey.AVAILABLE_AND_COMING_SOON
        : VehicleViewKey.AVAILABLE;
  }
  if (!params.limit) {
    params.limit = DEFAULT_LIMIT;
  }
  const discoveryConfig = useDiscoveryConfig();

  const { data, isLoading, isValidating, setSize } =
    useSWRInfinite<GetVehiclesResponse>(
      getInfiniteLoadKey({
        ...params,
        zipCode: region === CountryCode.US ? params?.zipCode : undefined,
        locale,
      }),
      discoveryConfig.apiFetchers.fetchProductListItems,
      {
        fallbackData: config?.fallbackData ? [config.fallbackData] : undefined,
        keepPreviousData: true,
        dedupingInterval: DEDUPING_INTERVAL_MS,
        revalidateFirstPage: false,
        revalidateOnFocus: false,
        onError: () => {
          if (config?.fallbackData) {
            showToast({
              status: 'warning',
              title: i18n.formatMessage('plp.slowInternet.title'),
              description: i18n.formatMessage('plp.slowInternet.description'),
            });
          }
        },
      }
    );

  const lastResponse = data?.[data.length - 1] ?? null;

  const products = config?.infinite
    ? (data?.reduce((acc, cur) => {
        acc.push(...cur.results);

        return acc;
      }, [] as GenericVehicleDetails[]) ?? [])
    : (lastResponse?.results ?? []);

  return {
    products,
    offset: lastResponse?.offset ?? 0,
    limit: params?.limit ?? DEFAULT_LIMIT,
    total: lastResponse?.total_results ?? 0,
    isLoading,
    isValidating,
    loadNextPage: () => {
      if (
        !isLoading &&
        !isValidating &&
        typeof lastResponse?.offset === 'number' &&
        lastResponse?.offset > 0
      ) {
        setSize((size) => size + 1);
      }
    },
  };
};
