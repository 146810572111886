import { cn } from '@finn/ui-utils';
import { Fragment } from 'react';

import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { extractVehicleAttributes } from '../helpers/extractVehicleAttributes';
import { SOURCES } from '../helpers/vehicle';
import { GenericVehicleDetails } from '../types';

export const AttributesRow = ({
  vehicle,
}: {
  vehicle: GenericVehicleDetails;
}) => {
  /*
     TODO: For blue_dragon source, we use trim_name, for other sources we use equipment_line
      @vitormalencar: This is a temporary solution, as we want to move out of airtable
 */
  const line =
    vehicle.source === SOURCES.BLUE_DRAGON ? 'trim_name' : 'equipment_line';

  const config = useDiscoveryConfig();

  // if is variant 2 we want to remove gearshift
  const subscriptionAttributes = config?.subscriptionAttributes || [
    { key: line },
    { key: 'fuel' },
  ];

  const colorCount = vehicle.color_list?.filter(
    (color) => color.availability || vehicle?.coming_soon
  ).length;

  const attributes = extractVehicleAttributes(vehicle, subscriptionAttributes);
  const shouldRenderColors = colorCount > 1;

  return (
    <div
      className={cn(
        'relative flex items-center overflow-hidden text-ellipsis whitespace-nowrap',
        {
          'inline-block whitespace-normal': config?.subscriptionAttributes,
        }
      )}
    >
      {attributes.map(({ text, key }, idx) => (
        <Fragment key={key}>
          <span className="body-12-light sm:body-14-light inline-block whitespace-nowrap py-1">
            {text}
          </span>
          {idx < attributes.length - 1 || shouldRenderColors ? (
            <div
              className={cn(
                'bg-pearl mx-1 my-0.5 inline-block h-1 min-h-1 w-1 min-w-1 rounded py-0.5 md:mx-2',
                shouldRenderColors ? '' : 'group-last:hidden'
              )}
            />
          ) : null}
        </Fragment>
      ))}

      {shouldRenderColors && (
        <span
          className={cn('body-12-light sm:body-14-light inline-block py-0.5')}
        >
          {colorCount > 1 ? colorCount : ''} {'Farben'}
        </span>
      )}
    </div>
  );
};
