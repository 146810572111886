import { useIntl } from '@finn/ui-utils';
import React from 'react';

import { useFormattedNumber } from '../../pdp';
import { isElectricVehicle } from '../helpers/vehicle';
import { GenericVehicleDetails } from '../types';

type Props = {
  vehicle: GenericVehicleDetails;
};
export const Consumption = ({ vehicle }: Props) => {
  const intl = useIntl();
  const isElectric = isElectricVehicle(vehicle.fuel);
  const consumption = useFormattedNumber(vehicle.consumption || 0);
  const consumptionUnit = `${isElectric ? 'kWh' : 'l'} / 100km`;
  const co2Emission = vehicle.co2emission || 0;

  return (
    // TODO: if experiment is rolled out, add new  "extra small" (2xs) font size to the design system
    <p className="text-iron font-inter font-regular text-[9px]">
      <span>
        {intl.formatMessage('plp.combinedConsumption', {
          consumption,
          unit: consumptionUnit,
        })}
      </span>
      <br />
      <span>
        {vehicle.co2_class
          ? intl.formatMessage('plp.combinedEmissionAndClass', {
              co2Emission,
              co2Class: vehicle.co2_class,
            })
          : intl.formatMessage('plp.combinedEmission', { co2Emission })}
      </span>
    </p>
  );
};
