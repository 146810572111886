import { useDownPayment } from '@finn/ua-featureflags';
import {
  App,
  cn,
  getFormattedPrice,
  useCurrentApp,
  useCurrentLocale,
  useIntl,
} from '@finn/ui-utils';
import get from 'lodash/get';

import { useForBusinessCookie } from '../../../helpers';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { getDefaultTerm, usePreDiscount } from '../../product-pricing';
import { GenericVehicleDetails } from '../types';

type Props = {
  vehicle: GenericVehicleDetails;
  smallerDesktopFont?: boolean;
  forceB2BPrice?: boolean;
  selectedTerm?: number;
  partnerDiscount?: number;
  showComparisonInfo?: boolean;
};

export const ProductPrice = ({
  vehicle,
  forceB2BPrice,
  selectedTerm,
  smallerDesktopFont,
  partnerDiscount = 0,
  showComparisonInfo,
}: Props) => {
  const { isDownPaymentEnabled } = useDownPayment();
  const { priceType, forceRegularPrice } = useDiscoveryConfig();

  const term = selectedTerm || getDefaultTerm(vehicle, { forceRegularPrice });

  const { isUSA } = useCurrentLocale();
  const i18n = useIntl();

  const forBusinessCookie = useForBusinessCookie();
  const isForBusiness = (!isUSA && forceB2BPrice) || forBusinessCookie;

  const priceKey =
    isDownPaymentEnabled && !forceRegularPrice
      ? `downpayment_prices.available_price_list`
      : `price.available_price_list`;

  let price = isForBusiness
    ? get(vehicle, `${priceKey}.b2b_${term}`, 0)
    : get(vehicle, `${priceKey}.b2c_${term}`, 0);

  if (useCurrentApp() === App.B2B) {
    price = get(vehicle, `${priceKey}.b2c_${term}`, 0);
  }

  if (isUSA && forBusinessCookie) {
    // for USA we have only one price for customers so we show it and remove the insurance amount
    price = get(vehicle, `${priceKey}.b2c_${term}`, 0);
  }

  let preDiscountPrice = usePreDiscount({
    vehicle,
    price,
    term,
    isForBusiness,
    isDownPayment: isDownPaymentEnabled,
  });

  let priceTotal = price;

  if (partnerDiscount > 0) {
    if (!preDiscountPrice) {
      preDiscountPrice = getFormattedPrice(i18n, priceTotal, 0);
    }
    priceTotal = priceTotal - priceTotal * partnerDiscount;
  }

  // TODO for some reason PLP cards do not have space between value and
  // eur, but localization forces it to have, so for back compatibility removing it
  const priceFormatted = getFormattedPrice(
    i18n,
    priceType === 'cents' ? priceTotal / 100 : priceTotal,
    0
  )?.replace(' ', '');

  const perMonthLabel = i18n.formatMessage(
    isForBusiness || forceRegularPrice
      ? 'general.perMonthWithoutVat'
      : 'general.perMonth'
  );

  // To prevent showing 0 euro prices for cars that dont have the selected term.
  if (!price) {
    return null;
  }

  return (
    <div
      className={cn('body-14-semibold py-1', {
        'sm:body-16-semibold': !smallerDesktopFont,
      })}
    >
      <div
        className={cn('row-y-2 mb-1 flex flex-wrap items-center md:mb-1.5', {
          'md:mb-1': showComparisonInfo,
        })}
      >
        {!selectedTerm && (
          <div>{i18n.formatMessage('plp.priceFrom')}&nbsp;</div>
        )}
        {preDiscountPrice && (
          <div className="text-iron mr-1 line-through">{preDiscountPrice}</div>
        )}
        {priceFormatted}&nbsp;
        <div className="body-12-light sm:body-14-light">{perMonthLabel}</div>
      </div>
    </div>
  );
};
